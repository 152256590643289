import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
import { NgForm } from '@angular/forms';
import {countries} from '../shared/components/store/country-data-store'
import { generate } from 'rxjs';
// import { log } from 'console';
import { ajax } from 'rxjs/ajax';
// import { UploadService } from './upload.service';
import { HttpClient } from '@angular/common/http';
// import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ViewChild,ElementRef } from "@angular/core";


@Component({
	selector: 'app-vigilance',
	templateUrl: './vigilance.component.html',
	styleUrls: ['./vigilance.component.css']
})
export class VigilanceComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public PledgeData: any;
  public rvnlBulletinsData: any;
  public PerformanceData: any;
  public ContactInfoData: any;
  public RvnlAboutData: any;
  public lang:any = sessionStorage.getItem('lang');
  public SaveComplain:any;
  public SaveComplain1:any;
  public isloded:boolean=false;
  public isloded1:boolean=false;
  public countries:any;
  public stateList:any ;
  public cityList:any;
  public city:any;
  public state:any;
  public Gender:any;
  public countery:any;
  public OTP:any;
  public OTPvalid:boolean=false;
  public register:boolean=false;
  public login:boolean=true;
  public complain:boolean=false;
  public isDisabled:boolean=true;
  public mobileNumber:boolean=false;
  public GenrateOtp:boolean=true;
  file: File = null;
  public displayStyle = "none";
  public displayStyle1 = "block";
  public displayStyle2 = "none";
  public complentModel="none";
  public fileStyle='none'
  public modalHeading:string;
  public modalvalue:string;
  public loginData:any;
  public statusData:any;
  public tableData:boolean=false;
  public logout:boolean=false;
  public complaint:any;
  public closeButton:any;
  public toster:any;
  public tosterDiv:boolean=false;
  public fileData:any;
  public fileLangth:number;
  public fileUrl:any;
  public nextFile:number;
  public preFile:number;
  public mobile:any;
  public status1:any;
  public secondFile:boolean=false;
  public thirdFile:boolean=false;
  public file1:any;
  public file2:any;
  public file3:any;
  myFiles:string [] = [];
  dtOptions: any = {};
  urlSafe: SafeResourceUrl;
  @ViewChild('fileUploader') fileUploader:ElementRef; 
  constructor(
    private libraryService: LibraryService,public http:HttpClient,public sanitizer: DomSanitizer
	) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu : [5, 10, 25],
      processing: true
    };
	}
  addSecond(){
    this.secondFile=true;
  }
  removeSecond(){
    this.secondFile=false;
    this.file2=null;
  }
  addThird(){
    this.thirdFile=true;
  }
  removeThird(){
    this.thirdFile=false;
    this.file3=null;
  }

  Signup(event: any){
    this.register=true;
    this.login=false;
  }
  backToLogin(event:any){
    this.register=false;
    this.login=true;
  }

  onSelectedFile1(event: any) {
    debugger;
        var file=null;
        var file=event.target.files[0];
        var extension = file.type;
        var fileSize=file.size;
        if(extension=='application/pdf' || extension=='application/PDF' || extension=='image/jpg' || extension=='image/jpeg' || extension=='image/png'){
          if(fileSize<=25000000){
            this.file1=event.target.files[0];
            // this.myFiles.push(event.target.files[0]);
          }else{
            window.scroll({ 
              top: 0, 
              left: 0, 
              behavior: 'smooth' 
            });
            $("#file").val('');
            // this.fileUploader.nativeElement.value = '';
            this.toster='Max file sixe will be 25 Mb';
            this.tosterDiv=true;
            this.hideAnimatedDiv();
            this.file = null;
            return false;
          }
          
        }else{
          window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
          });
          $("#file").val('');
          this.toster='Please upload the valid file like (pdf,jpg,jpeg,png)';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
          this.file = null;
          return false;
        }
      $("#file").val('');
  }
  onSelectedFile2(event: any) {
    debugger;
        var file=null;
        var file=event.target.files[0];
        var extension = file.type;
        var fileSize=file.size;
        if(extension=='application/pdf' || extension=='application/PDF' || extension=='image/jpg' || extension=='image/jpeg' || extension=='image/png'){
          if(fileSize<=25000000){
            this.file2=event.target.files[0];
            // this.myFiles.push(event.target.files[0]);
          }else{
            window.scroll({ 
              top: 0, 
              left: 0, 
              behavior: 'smooth' 
            });
            $("#file").val('');
            // this.fileUploader.nativeElement.value = '';
            this.toster='Max file sixe will be 25 Mb';
            this.tosterDiv=true;
            this.hideAnimatedDiv();
            this.file = null;
            return false;
          }
          
        }else{
          window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
          });
          $("#file").val('');
          this.toster='Please upload the valid file like (pdf,jpg,jpeg,png)';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
          this.file = null;
          return false;
        }
      $("#file").val('');
  }
  onSelectedFile3(event: any) {
    debugger;
        var file=null;
        var file=event.target.files[0];
        var extension = file.type;
        var fileSize=file.size;
        if(extension=='application/pdf' || extension=='application/PDF' || extension=='image/jpg' || extension=='image/jpeg' || extension=='image/png'){
          if(fileSize<=25000000){
            this.file3=event.target.files[0];
            // this.myFiles.push(event.target.files[0]);
          }else{
            window.scroll({ 
              top: 0, 
              left: 0, 
              behavior: 'smooth' 
            });
            $("#file").val('');
            // this.fileUploader.nativeElement.value = '';
            this.toster='Max file sixe will be 25 Mb';
            this.tosterDiv=true;
            this.hideAnimatedDiv();
            this.file = null;
            return false;
          }
          
        }else{
          window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
          });
          $("#file").val('');
          this.toster='Please upload the valid file like (pdf,jpg,jpeg,png)';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
          this.file = null;
          return false;
        }
      $("#file").val('');
  }
  
  fileRemove(indexOfFile){
    debugger;
    // for(var i=0;i<this.myFiles.length;i++){
      if (indexOfFile > -1) { 
        console.log(this.myFiles.splice(indexOfFile, 1));
        
      }
    // }    
    
  }
  Mobile(event:any){
    if (event.charCode != 0) {
			var regex = new RegExp("^[0-9]+$");
			var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
			if (!regex.test(key)) {
				event.preventDefault();
			}
		}
   
  }
  otpGenrate(){
      var number =$('#Mobile').val();
      if(number.toString().length==10){
        this.libraryService.otpSend(number).subscribe(result=>{
          this.OTP = result; 
          if(this.OTP==true){
            this.OTPvalid=true;
          }else{
            this.toster='OTP did not send something went wrong.Please try again later.';
            this.tosterDiv=true;
            this.hideAnimatedDiv();
          }
        })
      }else{
        this.toster='Please enter the valid mobile number';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
      }   
  }
  otpSubmit(){
    var userOtp=$('#OTP').val();
    var number =$('#Mobile').val(); 
    // alert('mfng');
    // alert(userOtp);
    this.libraryService.userotp(number,userOtp).subscribe(result=>{
      this.OTPvalid = result; 
      if(this.OTPvalid==true){
        this.toster='OTP verified successfully.';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        this.isDisabled=false;
        this.GenrateOtp=false;
        this.mobileNumber=true;
        this.OTPvalid=false;
        this.mobile=number
      }else if(this.OTPvalid==false){
        this.toster='Please enter the valid otp';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        this.OTPvalid=true;
      }else if(this.OTPvalid=='otp expire'){
        this.toster='Otp time has expired please generate a new otp';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        this.OTPvalid=true;
      }
    })
  }
  
    

 
  pincode(event:any){
    if (event.charCode != 0) {
			var regex = new RegExp("^[0-9]+$");
			var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
			if (!regex.test(key)) {
				event.preventDefault();
			}
    }
  }


  getGender(event:any){
    this.Gender= event.target.value;
  }
  getCountry(event:any){
    this.countery= event.target.value;
    this.libraryService.state(this.countery).subscribe(result => {
      this.stateList = result;
    });
  }

  name(event:any){
    if (event.charCode != 0) {
      var regex = new RegExp("^[a-zA-Z\\s]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
      }
    }
  }
  onRegister(data:any){
    let name = data.Fullname;
    let mobile = this.mobile;
    let email = data.email;
    let password = data.password;
    let cpassword = data.cpassword;
    if(email!=undefined){
      const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
      if (!emailRegex.test(email)) {
       
        this.toster='Invalid email address';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        return false;
      } 
    }
		var minNumberofChars = 8;
		var maxNumberofChars = 16;
		var regularExpression  = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#]{8,16}/;
		if(password.length < minNumberofChars || password.length > maxNumberofChars){
      // this.displayStyle = "block";
      // this.modalHeading="";
      // this.modalvalue="";
      // this.modalHeading="Numver";
      // this.modalvalue="Password should be atleast 8 character and max 16 character";
			
		}
		if(!regularExpression.test(password)) {
      
      this.toster='Password should be atleast 8 character and max 16 character\n password should contain:-\nAt least one upper case letter: (A – Z) \nAt least one lower case letter: (a - z) \nAt least one number: (0 - 9) \nAt least one Special Characters()';
      this.tosterDiv=true;
      this.hideAnimatedDiv();
			return false;
		}
    if(password==cpassword){
      if(name && mobile && email && password){
        var formData2= new FormData();
        formData2.append('name',name);
        formData2.append('mobile',mobile);
        formData2.append('email',email);
        formData2.append('password',this.SHA256(password));
        this.libraryService.getRegisterLog(formData2).subscribe(result=>{
          this.SaveComplain = result;           
          
          this.toster='User registered succesfully';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
          this.login=true;
          this.register=false;
          this.mobileNumber=false;
          this.GenrateOtp=true;
          this.isDisabled=true;

        })
        
      }else{
        if(name==''){

          this.toster='Please enter name';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
          return false;
        }
        if(mobile==''){
          
          this.toster='Please enter your mobile number';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
          return false;
        }
        if(email==''){
         
          this.toster='Please enter email';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
          return false;
        }
        if(password==''){
         
          this.toster='Please enter password';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
          return false;
        }
        if(cpassword==''){
         
          this.toster='Please enter confirmed password';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
          return false;
        }
      }
    }else{
     
      this.toster='Password and confirm password does not match';
      this.tosterDiv=true;
      this.hideAnimatedDiv();
    }
    
  }
  userLogin(data:any){
    let email = data.Useremail;
    let password = this.SHA256(data.password);
    if(email!=undefined){
      const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
      if (!emailRegex.test(email)) {
        this.toster='Invalid email address';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        return false;
      } 
    }
    
      if(email && password){
        var formData3=new FormData();
        formData3.append('email',email);
        formData3.append('password',password);
        this.libraryService.getUserLogin(formData3).subscribe(result=>{
          this.loginData = result; 
          // console.log(this.loginData);
          
          if(this.loginData.length!=0){
            this.login=false;
            this.tableData=true;
            this.logout=true;
           
            this.checkStatus();
           
          }else{
            this.toster='Login failed please check your email id and password';
            this.tosterDiv=true;
            this.hideAnimatedDiv();
          }
         
        })
        
      }else{
        if(email==''){
          
          this.toster='Please enter email';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
        }
        if(password==''){
         
          this.toster='Please enter password';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
        }

      }
  }

  SHA256(s) {
      var chrsz = 8;
      var hexcase = 0;

      function safe_add(x, y) {
          var lsw = (x & 0xFFFF) + (y & 0xFFFF);
          var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
          return (msw << 16) | (lsw & 0xFFFF);
      }

      function S(X, n) {
          return (X >>> n) | (X << (32 - n));
      }

      function R(X, n) {
          return (X >>> n);
      }

      function Ch(x, y, z) {
          return ((x & y) ^ ((~x) & z));
      }

      function Maj(x, y, z) {
          return ((x & y) ^ (x & z) ^ (y & z));
      }

      function Sigma0256(x) {
          return (S(x, 2) ^ S(x, 13) ^ S(x, 22));
      }

      function Sigma1256(x) {
          return (S(x, 6) ^ S(x, 11) ^ S(x, 25));
      }

      function Gamma0256(x) {
          return (S(x, 7) ^ S(x, 18) ^ R(x, 3));
      }

      function Gamma1256(x) {
          return (S(x, 17) ^ S(x, 19) ^ R(x, 10));
      }

      function core_sha256(m, l) {
          var K = new Array(0x428A2F98, 0x71374491, 0xB5C0FBCF, 0xE9B5DBA5, 0x3956C25B, 0x59F111F1, 0x923F82A4, 0xAB1C5ED5, 0xD807AA98, 0x12835B01, 0x243185BE, 0x550C7DC3, 0x72BE5D74, 0x80DEB1FE, 0x9BDC06A7, 0xC19BF174, 0xE49B69C1, 0xEFBE4786, 0xFC19DC6, 0x240CA1CC, 0x2DE92C6F, 0x4A7484AA, 0x5CB0A9DC, 0x76F988DA, 0x983E5152, 0xA831C66D, 0xB00327C8, 0xBF597FC7, 0xC6E00BF3, 0xD5A79147, 0x6CA6351, 0x14292967, 0x27B70A85, 0x2E1B2138, 0x4D2C6DFC, 0x53380D13, 0x650A7354, 0x766A0ABB, 0x81C2C92E, 0x92722C85, 0xA2BFE8A1, 0xA81A664B, 0xC24B8B70, 0xC76C51A3, 0xD192E819, 0xD6990624, 0xF40E3585, 0x106AA070, 0x19A4C116, 0x1E376C08, 0x2748774C, 0x34B0BCB5, 0x391C0CB3, 0x4ED8AA4A, 0x5B9CCA4F, 0x682E6FF3, 0x748F82EE, 0x78A5636F, 0x84C87814, 0x8CC70208, 0x90BEFFFA, 0xA4506CEB, 0xBEF9A3F7, 0xC67178F2);
          var HASH = new Array(0x6A09E667, 0xBB67AE85, 0x3C6EF372, 0xA54FF53A, 0x510E527F, 0x9B05688C, 0x1F83D9AB, 0x5BE0CD19);
          var W = new Array(64);
          var a, b, c, d, e, f, g, h, i, j;
          var T1, T2;
          m[l >> 5] |= 0x80 << (24 - l % 32);
          m[((l + 64 >> 9) << 4) + 15] = l;
          var i:any;
          for (i = 0; i < m.length; i += 16) {
              a = HASH[0];
              b = HASH[1];
              c = HASH[2];
              d = HASH[3];
              e = HASH[4];
              f = HASH[5];
              g = HASH[6];
              h = HASH[7];
              var j:any;
              for (j = 0; j < 64; j++) {
                  if (j < 16)
                      W[j] = m[j + i];
                  else
                      W[j] = safe_add(safe_add(safe_add(Gamma1256(W[j - 2]), W[j - 7]), Gamma0256(W[j - 15])), W[j - 16]);
                  T1 = safe_add(safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]), W[j]);
                  T2 = safe_add(Sigma0256(a), Maj(a, b, c));
                  h = g;
                  g = f;
                  f = e;
                  e = safe_add(d, T1);
                  d = c;
                  c = b;
                  b = a;
                  a = safe_add(T1, T2);
              }

              HASH[0] = safe_add(a, HASH[0]);
              HASH[1] = safe_add(b, HASH[1]);
              HASH[2] = safe_add(c, HASH[2]);
              HASH[3] = safe_add(d, HASH[3]);
              HASH[4] = safe_add(e, HASH[4]);
              HASH[5] = safe_add(f, HASH[5]);
              HASH[6] = safe_add(g, HASH[6]);
              HASH[7] = safe_add(h, HASH[7]);
          }
          return HASH;
      }

      function str2binb(str) {
          var bin = Array();
          var mask = (1 << chrsz) - 1;
          for (var i = 0; i < str.length * chrsz; i += chrsz) {
              bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - i % 32);
          }
          return bin;
      }

      function Utf8Encode(string) {
          string = string.replace(/\r\n/g, "\n");
          var utftext = "";
          for (var n = 0; n < string.length; n++) {

              var c = string.charCodeAt(n);
              if (c < 128) {
                  utftext += String.fromCharCode(c);
              } else if ((c > 127) && (c < 2048)) {
                  utftext += String.fromCharCode((c >> 6) | 192);
                  utftext += String.fromCharCode((c & 63) | 128);
              } else {
                  utftext += String.fromCharCode((c >> 12) | 224);
                  utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                  utftext += String.fromCharCode((c & 63) | 128);
              }

          }

          return utftext;
      }

      function binb2hex(binarray) {
          var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
          var str = "";
          for (var i = 0; i < binarray.length * 4; i++) {
              str += hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 0xF) +
                  hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8)) & 0xF);
          }
          return str;
      }

      s = Utf8Encode(s);
      return binb2hex(core_sha256(str2binb(s), s.length * chrsz));
  }
  checkStatus(){
    var mobile =this.loginData[0]['mobile'];
    let columnName='Mobile';
    this.libraryService.complaintStatus(mobile,columnName).subscribe(result=>{
      this.statusData = result; 
      // this.showHideFunction();
      if(this.statusData.length>0){
       this.tableData=true;
      }else{
        this.tableData=false;
        this.complain=true;
        this.countery='101';
        this.getCountrys();
      }
    })
  }
  public showHideFunction(id) {
     
          if(this.statusData[id]['status']=='open' || this.statusData[id]['status']=='to be filed' || this.statusData[id]['status']=='Filing in progress' || this.statusData[id]['status']=='Filing complete' || this.statusData[id]['status']=='to be verified'|| this.statusData[id]['status']=='Verification in progress'){
        return 1;
        }else if(this.statusData[id]['status']=='Verification complete' ||this.statusData[id]['status']=='to be investigated'||this.statusData[id]['status']=='Investigation in progress' ||this.statusData[id]['status']=='Investigation Report Submitted' ||this.statusData[id]['status']=='Investigation report Approved' || this.statusData[id]['status']=='Report Decision Actioned'){
          return 2;
        }else{
          return 3;
        }
     

  }
  newComplaint(data:any){
    this.complain=true;
    this.tableData=false;
    this.countery='101';
    this.getCountrys();

  }
  getCountrys(){
    this.libraryService.country().subscribe(result => {
      this.countries = result;
    });
    this.libraryService.state(this.countery).subscribe(result => {
      this.stateList = result;
    });
    
  }
  getState(event:any){
    this.state= event.target.value;
    this.libraryService.city(this.state).subscribe(result => {
      this.cityList = result;
    });
  }
  getcity(event:any){
    this.city= event.target.value;
  }
  complaint1(event:any){
    debugger;
    let status = event;
    let columnName='ComplainNo';
    this.libraryService.complaintStatus(status,columnName).subscribe(result=>{
      this.complaint = result; 
      // this.countery=this.complaint[0]['State']
      // this.getCountrys();

      if(this.complaint.length>0){
        this.libraryService.fileget(event,'10').subscribe(result=>{
          this.fileData = result;  
          this.fileLangth=this.fileData.length;
          if((this.fileLangth)>0){
            this.fileUrl=this.baseUrl+'uploads/vigilance/'+this.fileData[0]['fileName'];
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
            this.fileStyle='none';
            if((this.fileLangth)>1){
              this.nextFile=1;
            }
          }
          
          // console.log(this.fileData);
          
        })
        this.tableData=false;
        this.complentModel="block";
        this.isDisabled=true;
      } 
    })
  }
  fileNext(data){
    this.fileUrl=this.baseUrl+'uploads/vigilance/'+this.fileData[data]['fileName'];
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
    if(this.fileLangth>data){
      this.nextFile=data+1;
      this.preFile=data-1;
    }
    this.fileStyle='block';
    
  }
  filePreview(data){
    this.fileUrl=this.baseUrl+'uploads/vigilance/'+this.fileData[data]['fileName'];
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
      this.nextFile=data+1;
      this.preFile=data-1;
    this.fileStyle='block';
  }
  closePopup1(){
    this.tableData=true;
    this.thirdFile=false;
    this.secondFile=false;
    this.complentModel="none";
  }
  back(){
    this.tableData=true;
    this.complain=false;

  }
  onClickSubmit(data:any){
    debugger;
    this.myFiles=[this.file1,this.file2,this.file3];
    let Name = this.loginData[0]['name'];
    let Address1 = data.Address1;
    let Address2 = data.Address2;
    let Complaint = data.Complaint.toString();
    let Mobile = this.loginData[0]['mobile'];
    let Pincode = data.Pincode;
    let Subject = data.Subject;
    let email = this.loginData[0]['email'];
    let country=this.countery;
    let state=this.state;
    let city=this.city;
    let gender =this.Gender;
    if(email!=undefined){
      const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
      if (!emailRegex.test(email)) {
        
        this.toster='Invalid email address';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        return false;
      } 
    }
    
    if(Name && Complaint && Mobile && Subject && gender && country && state && city && Pincode &&  Address1){
      var formData1= new FormData();

      formData1.append('name',Name);
      formData1.append('Address1',Address1);
      formData1.append('Address2',Address2);
      formData1.append('gender',gender);
      formData1.append('country',country);
      formData1.append('state',state);
      formData1.append('city',city);
      formData1.append('Complaint',Complaint);
      formData1.append('Mobile',Mobile);
      formData1.append('Pincode',Pincode);
      formData1.append('Subject',Subject);
      formData1.append('email',email);
      // formData1.append('file',file);
      this.libraryService.getcomplaint_log(formData1).subscribe(result=>{
        this.SaveComplain = result; 
        if(this.SaveComplain.length!=0){
          const formData = new FormData();
          for (var i = 0; i < this.myFiles.length; i++) { 
            formData.append("file"+i, this.myFiles[i]);
          }
          formData.append("ComplainNo",this.SaveComplain);
          this.http.post(this.baseUrl+'Frontend/fileUpload',formData).subscribe(result=>{
          debugger  
          this.SaveComplain1 = result; 
          // console.log(this.SaveComplain1);
          if(this.SaveComplain != null && this.SaveComplain != undefined && this.SaveComplain != '')
          {
            this.displayStyle = "block";
            this.displayStyle1 = "none";
            this.displayStyle2 = "block";
            this.modalHeading="";
            this.modalvalue
            this.modalvalue="Complaint registered successfully. Please note that your complaint number is "+this.SaveComplain;
            this.myFiles=[];
          }
          })
         
         
        }
       
        
      })
      
    }else{
      if(Name==''){
        this.toster='Please enter Name';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        return false;
      }
      if(gender==undefined){
        this.toster='Please select gender';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        return false;
      }
      if(state==undefined){
        this.toster='Please select state';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        return false; 
      }
      if(city==undefined){
        this.toster='Please select city';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        return false; 
        }
        if(Pincode==''){
          this.toster='Please enter pincode';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
          return false; 
          }
        
       if(Address1==''){
        this.toster='Please enter the address';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        return false; 
        }
        if(Mobile==''){
          this.toster='Please enter your mobile number';
          this.tosterDiv=true;
          this.hideAnimatedDiv();
          return false; 
       }
      if(Subject==''){
        this.toster='Please enter subject';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        return false;
      }
      if(Complaint==''){
        this.toster='Please enter Complaint';
        this.tosterDiv=true;
        this.hideAnimatedDiv();
        return false;
      }
      
      
      
      
    }
  }
  closePopup() { 
    this.thirdFile=false;
    this.secondFile=false;
    this.displayStyle = "none"; 
  } 
  closePopup2() { 
    this.thirdFile=false;
    this.secondFile=false;
    this.displayStyle = "none";
    this.tableData=true;
    this.complain=false;
    this.checkStatus()
    // window.location.reload();
  } 
  closePopupfile(){
    this.thirdFile=false;
    this.secondFile=false;
    this.fileStyle='none';
    this.complentModel='block';
  }
  logOut(){
    this.login=true;
    this.tableData=false;
    this.register=false;
    this.complain=false;
    this.loginData='';
    // window.location.reload();
  }
  hideAnimatedDiv() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    setTimeout(() => {
      this.tosterDiv=false;
    }, 10000);
 }
 openPopupfile(){
    this.complentModel='none';
    this.fileStyle='block';
 }

	ngOnInit() {
    this.libraryService.getVigilancePledge(this.lang).subscribe(result => {
      this.PledgeData = result;
    });

    this.libraryService.getRvnlBulletins(this.lang).subscribe(result => {
      this.rvnlBulletinsData = result;
      this.isloded = true;
    });

    this.libraryService.getVigilancePerformance(this.lang).subscribe(result => {
      this.PerformanceData = result;
      this.isloded1 = true;
    });

    this.libraryService.getVigilanceContactInfo(this.lang).subscribe(result => {
      result.forEach(element => {
            element.email = (element.email.replace('@', '[at]')).replace('.', '[dot]');;
      });
      this.ContactInfoData = result;
    });

    this.libraryService.getVigilanceAboutRvnl(this.lang).subscribe(result => {
      this.RvnlAboutData = result;
    });
    

  }

}
