import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../shared/library.service';
import { environment as ENV } from '../../environments/environment.prod';
@Component({
	selector: 'app-mou',
	templateUrl: './mou.component.html',
	styleUrls: ['./mou.component.css']
})
export class MouComponent implements OnInit {
  public baseUrl: string = ENV.BASE_URL;
  public MouData: any;
  public lang:any = sessionStorage.getItem('lang');
  public isloded:boolean=false;
  dtOptions: any = {};

  constructor(
    private libraryService: LibraryService,
	) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu : [5, 10, 25],
      processing: true
    };
	}


	ngOnInit() {
    this.libraryService.getOurMous(this.lang).subscribe(result => {
      this.MouData = result;
      this.isloded = true;
    });

  }

}
